<template>
  <el-scrollbar max-height="95dvh">
    <div class="common-layout">
      <el-container>
        <el-header :class="activeTab === 'riskAndAdaptationRegisterTab' ? 'custom-header-class' : ''">
          <el-row class="mt-2">
            <el-col>
              <el-card shadow="always"> 
                <el-row>
                  <el-col :span="1" class="custom-icon-space">
                    <el-avatar :size="30" class="arc-light-orange-bg">
                      <el-icon><School /></el-icon>
                    </el-avatar>
                  </el-col>
                  <el-col :span="23">
                    <el-breadcrumb separator="/" class="mt-2">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Campus Assessment</el-breadcrumb-item>
                      <!-- <el-breadcrumb-item>{{ campusLabel }}</el-breadcrumb-item> -->
                    </el-breadcrumb>
                  </el-col>
                </el-row>
                <el-row v-if="activeTab=='riskAndAdaptationRegisterTab'" :gutter="20" class="mt-3">
                  <el-col :span="1">
                    <el-space fill wrap>
                      <el-text size="small" class="mt-2 mx-1 arc-primary-font" tag="b">Region</el-text>
                    </el-space>
                  </el-col>
                  <el-col :span="4">
                    <el-space fill wrap>
                      <el-select
                        v-model="advanceFilters.selectedRegion"
                        @change="e => handleOnChangeFilter({ field: 'selectedRegion', value: e})"
                        placeholder="Region"
                        filterable
                        clearable
                        @clear="resetFilterToAll('selectedRegion')"
                      >
                        <el-option
                          v-for="item in regionsFilterSelection"
                          :key="item.code"
                          :label="item.label"
                          :value="item.code"
                        />
                      </el-select>
                    </el-space>
                  </el-col>
                  <el-col :span="1">
                    <el-space fill wrap>
                      <el-text size="small" class="mt-2 mx-1 arc-primary-font" tag="b">Campus</el-text>
                    </el-space>
                  </el-col>
                  <el-col :span="4">
                    <el-space fill wrap>
                      <el-select
                        v-model="advanceFilters.selectedCampus2"
                        @change="e => handleOnChangeFilter({ field: 'selectedCampus2', value: e})"
                        placeholder="Campus"
                        filterable
                        clearable
                        @clear="resetFilterToAll('selectedCampus2')"
                      >
                        <el-option
                          v-for="item in campusFilterSelection"
                          :key="item.code"
                          :label="item.label"
                          :value="item.code"
                        />
                      </el-select>
                    </el-space>
                  </el-col>
                </el-row>
                
              </el-card>
            </el-col>
          </el-row>
          
        </el-header>
        <el-main :class="activeTab === 'riskAndAdaptationRegisterTab' ? 'custom-main-class mt-2' : 'mt-2'">
          <el-tabs type="border-card" tab-position="bottom" v-model="activeTab" >
            <el-tab-pane name="climateHazardTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-earth-americas"></i>
                  <el-text tag="b" class="arc-primary-font"> Climate Hazard Explorer</el-text>
                </span>
              </template>

              <iframe src="https://aus.gis.arcadis.com/portal/apps/dashboards/ab3f18b773a546d4ac29bacaacc1f5e3"
              class="custom-iframe-gis"
              ></iframe>

            </el-tab-pane>
            <el-tab-pane name="riskModelDashboardTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-square-poll-vertical"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk Model Dashboard</el-text>
                </span>
              </template>
              <!-- <el-card shadow="never" class="mb-3">
                <el-text class="mx-1 arc-primary-font" tag="b">Select Campus <el-icon><School /></el-icon></el-text>
                <el-select
                  v-model="selectedCampus"
                  placeholder="Select Campus"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in campusList"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-card> -->
              <el-card shadow="never" class="mb-3">
                <span class="el-text mx-1 arc-primary-font fw-bold">Select Campus <el-icon ><School /></el-icon></span>
                <el-select v-if="activeTab=='riskModelDashboardTab'" v-model="selectedCampus" placeholder="Select Campus" size="large">
                  <el-option
                    v-for="item in campusList"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-card>
              <el-card >
                <iframe :src="dashboardURL"
                class="custom-iframe-powerbi"
                ></iframe>
              </el-card>
            </el-tab-pane>
            <el-tab-pane name="riskAndAdaptationRegisterTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-table"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk and Adaptation Register</el-text>
                </span>
              </template>
              <!-- <el-card shadow="never" class="mb-3">
                <el-text class="mx-1 arc-primary-font" tag="b">Select Campus <el-icon><School /></el-icon></el-text>
                <el-select
                  v-model="selectedCampus"
                  placeholder="Select Campus"
                  size="large"
                  filterable
                >
                  <el-option
                    v-for="item in campusList"
                    :key="item.code"
                    :label="item.label"
                    :value="item.code"
                  />
                </el-select>
              </el-card> -->
              <el-card shadow="never" class="mb-3">
                <Dashboard
                  :initialCRAList="initialCRAList"
                  type="campus"
                />
              </el-card>
              <el-card shadow="never" class="mb-3">
                <AssessmentFilter
                  @changeFilter="handleOnChangeFilter"
                  :initialAdvanceFilters="advanceFilters"
                  type="campus"
                />
              </el-card>
              <el-tabs
                v-model="activeRegisterTab"
                tab-position="left"
              >
                <el-tab-pane label="RISK" name="riskTab">
                  <RiskTable
                    @fetchCRA="handleOnFetchCRA"
                    :filters="advanceFilters"
                    :initialCRAList="initialCRAList"
                    :initialLoadstate="initialLoadstate"
                    type="campus"
                  />
                </el-tab-pane>
                <el-tab-pane label="ADAPTATION" name="adaptationTab">
                  <AdaptationTable
                    @fetchCRA="handleOnFetchCRA"
                    :filters="advanceFilters"
                    :initialCRAList="initialCRAList"
                    :initialCampusLabel="initialCampusLabel"
                    :initialLoadstate="initialLoadstate"
                    type="campus"
                  />
                </el-tab-pane>
              </el-tabs>
              
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-container>
    </div>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import AdaptationTable from './AdaptationTable.vue'
import AssessmentFilter from './AssessmentFilter.vue'
import Dashboard from './Dashboard.vue'
import RiskTable from './RiskTable.vue'
import axios from 'axios';
import { baseApiUrl } from "../../config.js";
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import {
  Suitcase,
  LocationInformation,
  School
} from '@element-plus/icons-vue'

export default {
  name: 'Campus',
  components: {
    AdaptationTable,
    AssessmentFilter,
    Dashboard,
    RiskTable,
    LocationInformation,
    School,
    Suitcase,
  },
  data() {
    return {
      activeTab: "climateHazardTab",
      activeRegisterTab: ref('riskTab'),
      tabledata: [],
      selectedCampus: null,
      advanceFilters: {
        selectedRegion: "",
        selectedCampus2: "",
        selectedAssetLevel2: "all",
        selectedClimateHazard: "all",
        selectedConsequence: "all",
        selectedRiskType: "all",
        selectedSTConsequence: "all",
        selectedSTLikelihood: "all",
        selectedSTRating: [],
        selectedLTConsequence: "all",
        selectedLTLikelihood: "all",
        selectedLTRating: [],
      },
      rawCRAList: [],
      initialCampusLabel: null,
      initialLoadstate: false,
    }
  },
  computed: {
    campusLabel: {
        get() {
            return this.$store.state.climateRiskModule.campusList.filter(obj=>{return obj.code == this.$store.state.climateRiskModule.currentCampus})[0].label
        }
    },
    campusList: {
        get() {
            return this.$store.state.climateRiskModule.campusList
        }
    },
    currentAssessment: {
        get() {
            return this.$store.state.climateRiskModule.currentAssessment
        }
    },
    currentCampusCode: {
        get() {
            return this.$store.state.climateRiskModule.currentCampus?this.$store.state.climateRiskModule.currentCampus:"albury"
        }
    },
    dashboardURL: {
        get() {
            const climateDashboardURL = this.$store.state.mainModule.assessmentMapScenes.filter(
                obj => obj.code == this.$store.state.mainModule.currentAssessment
            )[0]?.climateDashboardURL
            const campusLabel = this.$store.state.climateRiskModule.campusList.filter(
                obj => { return obj.code == this.$store.state.climateRiskModule.currentCampus}
            )[0]?.label
            return `${climateDashboardURL}&$filter=Risk_x0020_Model/Location eq '${campusLabel}'`
        }
    },
    initialCRAList: {
      get() {
        let filteredList = this.rawCRAList.map(rawCra => {
          delete Object.assign(rawCra, {['risk_adaptations']: rawCra['campus_risk_adaptations'] })['campus_risk_adaptations'];
          return rawCra
        })
        if (this.advanceFilters.selectedRegion !== "") {
          filteredList = filteredList.filter((cra) => 
            this.advanceFilters.selectedRegion === cra.region_code
          )
        }
        if (this.advanceFilters.selectedCampus2 !== "") {
          filteredList = filteredList.filter((cra) => 
            this.advanceFilters.selectedCampus2 === cra.campus_code
          )
        }
        if (this.advanceFilters.selectedClimateHazard !== "all") {
          // Note: recursive error occurs when filteredList is not in structuredClone
          filteredList = structuredClone(filteredList.filter((cra) => 
            cra.climate_variables.includes(this.advanceFilters.selectedClimateHazard)
          ))
        }
        if (this.advanceFilters.selectedAssetLevel2 !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedAssetLevel2 === cra.sub_asset_type_code
          )
        }
        if (this.advanceFilters.selectedConsequence !== "all") {
          // Note: recursive error occurs when filteredList is not in structuredClone
          filteredList = structuredClone(filteredList.filter((cra) =>
            cra.consequence_areas.includes(this.advanceFilters.selectedConsequence)
          ))
        }
        if (this.advanceFilters.selectedRiskType !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedRiskType === cra.risk_type_code
          )
        }
        // Short term
        if(this.advanceFilters.selectedSTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTConsequence === cra.st_climate_risk_consequence_code
          )
        }
        if(this.advanceFilters.selectedSTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTLikelihood === cra.st_climate_risk_likelihood_code
          )
        }
        if(this.advanceFilters.selectedSTRating.length && this.advanceFilters.selectedSTRating !== 'all') {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTRating.some(rating => rating === cra.st_climate_risk_rating_code)
          )
        }
        // Long term
        if(this.advanceFilters.selectedLTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTConsequence === cra.lt_climate_risk_consequence_code
          )
        }
        if(this.advanceFilters.selectedLTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTLikelihood === cra.lt_climate_risk_likelihood_code
          )
        }
        if(this.advanceFilters.selectedLTRating.length && this.advanceFilters.selectedLTRating !== 'all') {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTRating.some(rating => rating === cra.lt_climate_risk_rating_code)
          )
        }
        
        return filteredList
      }
    },
    campusFilterSelection: {
      get() {
        let tempList = [...this.$store.state.climateRiskModule.campusList]

        // filter campus options if there is a selected campus
        if (this.advanceFilters.selectedRegion !== '') {
          tempList = tempList.filter(campus => campus.region_code === this.advanceFilters.selectedRegion)
        }

        return [...tempList]
      }
    },
    regionsFilterSelection: {
      get() {
        return [...this.$store.state.climateRiskModule.regionsList]
      }
    },
  },
  watch: {
    selectedCampus(val) {
      this.$store.commit('climateRiskModule/SET_CURRENT_CAMPUS', val)
    },
  },
  methods: {
    handleOnFetchCRA: function(campusCode) {
      const current = this
      current.initialLoadstate = true

      const url = baseApiUrl + 'campus-risks'

      axios.get(url).then(({ data }) => {
        if (data) {
          current.rawCRAList = data.map(risk => {
            risk.parent_risk_statement = risk.portfolio_risk_statement
            risk.consequence_areas = risk.campus_risks_consequence_areas?.map(consequence => consequence.code) || []
            risk.consequence_areas_labels = risk.campus_risks_consequence_areas?.map(consequence => consequence.label) || []
            risk.climate_variables = risk.campus_risks_climate_variables?.map(climateVar => climateVar.code) || []
            risk.climate_variables_labels = risk.campus_risks_climate_variables?.map(climateVar => climateVar.label) || []

            return risk
          });
        }
      }).catch(() => {
        ElNotification({
          type: 'error',
          message: 'Failed to get data' + campusCode + "...",
          title: 'ERROR'
        })
      }).finally(() => {
        current.initialLoadstate = false
      })
    },
    handleOnChangeFilter: function({ field, value }) {
      if (field === "selectedCampus") {
        this.selectedCampus = value
      } else {
        this.advanceFilters[field] = value
      }

      if (field === 'selectedRegion') {
        this.advanceFilters.selectedCampus2 = ''
      }
    },
  },
  beforeMount(){
    const current = this
    const currentAssessmentItem = current.$route.path.replace("/", "")
    this.$store.commit('mainModule/setCurrentAssessment', currentAssessmentItem)
    current.selectedCampus = current.currentCampusCode
    this.handleOnFetchCRA()
  },
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.custom-iframe-powerbi {
  min-height: 55vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
.custom-iframe-gis {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
.fw-bold {
  font-weight: bold;
}

::v-deep .custom-header-class {
  height: 126px;
}

::v-deep .custom-main-class {
  padding-top: 5px  !important;
}
</style>
  