/*eslint-disable*/
import axios from "axios";
import { baseApiUrl } from "../../../config.js";

const state = {
  currentAssessment: null,
  // currentCampus: null,
  assessmentMapScenes: [
    {
      code: "portfolio",
      mapId: "661f23e3cd98437e80a3f1be2a24c4a2",
      sceneId: "",
      climateDashboardURL:
        "https://app.powerbi.com/reportEmbed?reportId=65d74f6b-0df3-4f69-8740-ee9393516952&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false",
      // climateDashboardURL: '&filterPaneEnabled=false&navContentPaneEnabled=false',
      vulDashboardURL: "",
    },
    {
      code: "enterprise",
      mapId: "661f23e3cd98437e80a3f1be2a24c4a2",
      sceneId: "",
      // climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=9413f17f-84eb-4f42-8c93-e52d0d44be34&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false&navContentPaneEnabled=false',
      climateDashboardURL:
        "https://app.powerbi.com/reportEmbed?reportId=9931ca69-d16d-491c-bab4-6f590717a513&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false",
      vulDashboardURL: "",
    },
    {
      code: "region",
      mapId: "661f23e3cd98437e80a3f1be2a24c4a2",
      sceneId: "",
      // climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=9413f17f-84eb-4f42-8c93-e52d0d44be34&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false&navContentPaneEnabled=false',
      climateDashboardURL:
        "https://app.powerbi.com/reportEmbed?reportId=9931ca69-d16d-491c-bab4-6f590717a513&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false",
      vulDashboardURL: "",
    },
    {
      code: "campus",
      mapId: "661f23e3cd98437e80a3f1be2a24c4a2",
      sceneId: "",
      // climateDashboardURL: 'https://app.powerbi.com/reportEmbed?reportId=9413f17f-84eb-4f42-8c93-e52d0d44be34&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false&navContentPaneEnabled=false',
      climateDashboardURL:
        "https://app.powerbi.com/reportEmbed?reportId=63c8fb2f-835b-4cc7-9839-4f163d67561d&autoAuth=true&ctid=7f90057d-3ea0-46fe-b07c-e0568627081b&filterPaneEnabled=false",
      vulDashboardURL: "",
    },
  ],
  // currentCRAList: [],
  // campusList: [],
  // enterpriseList: [],
  // portfolioList: [],
  // adaptationTypesList: [],
  // subAssetTypesList: [],
  // adaptationCategoriesList: [],
  // adaptationCobenefitList: [],
  // climateHazardsList: [],
  // consequenceCategoriesList: [],
  // riskTypesList: [],
  // regionsList: [],
  // assessmentConsequencesList: [],
  // assessmentLikelihoodList: [],
  // assessmentRatingsList: [],
  // campusAdaptationMeasuresList: [],
  // regionAdaptationMeasuresList: [],
  // campusCoreAdaptationActionsList: [],
  // regionCoreAdaptationActionsList: [],
  // currentRegion: null,
};

const mutations = {
  setCurrentAssessment(state, payload) {
    state.currentAssessment = payload;
  },
  clearCurrentAssessment(state) {
    state.currentAssessment = null;
  },
  // setCurrentCampus(state, payload) {
  //   state.currentCampus = payload;
  // },
  // clearCurrentCampus(state) {
  //   state.currentCampus = null;
  // },
  // setCampuses(state, payload) {
  //   state.campusList.push(payload);
  // },
  // setEnterpriseList(state, payload) {
  //   state.enterpriseList = payload;
  // },
  // setPortfolioList(state, payload) {
  //   state.portfolioList = payload;
  // },
  // clearCampuses(state) {
  //   state.campusList = [];
  // },
  // setSubAssetTypes(state, payload) {
  //   state.subAssetTypesList.push(payload);
  // },
  // clearSubAssetTypes(state) {
  //   state.subAssetTypesList = [];
  // },
  // setAdaptationTypes(state, payload) {
  //   state.adaptationTypesList.push(payload);
  // },
  // clearAdaptationTypes(state) {
  //   state.adaptationTypesList = [];
  // },
  // setAdaptationCategories(state, payload) {
  //   state.adaptationCategoriesList.push(payload);
  // },
  // clearAdaptationCategories(state) {
  //   state.adaptationCategoriesList = [];
  // },
  // setAdaptationCobenefit(state, payload) {
  //   state.adaptationCobenefitList.push(payload);
  // },
  // clearAdaptationCobenefit(state) {
  //   state.adaptationCobenefitList = [];
  // },
  // setClimateHazards(state, payload) {
  //   state.climateHazardsList.push(payload);
  // },
  // clearClimateHazards(state) {
  //   state.climateHazardsList = [];
  // },
  // setConsequenceCategories(state, payload) {
  //   state.consequenceCategoriesList.push(payload);
  // },
  // clearConsequenceCategories(state) {
  //   state.consequenceCategoriesList = [];
  // },
  // setRiskTypes(state, payload) {
  //   state.riskTypesList.push(payload);
  // },
  // clearRiskTypes(state) {
  //   state.riskTypesList = [];
  // },
  // setRegions(state, payload) {
  //   state.regionsList.push(payload);
  // },
  // clearRegions(state) {
  //   state.regionsList = [];
  // },
  // setAssessmentConsequences(state, payload) {
  //   state.assessmentConsequencesList.push(payload);
  // },
  // clearAssessmentConsequences(state) {
  //   state.assessmentConsequencesList = [];
  // },
  // setAssessmentLikelihood(state, payload) {
  //   state.assessmentLikelihoodList.push(payload);
  // },
  // clearAssessmentLikelihood(state) {
  //   state.assessmentLikelihoodList = [];
  // },
  // setAssessmentRatings(state, payload) {
  //   state.assessmentRatingsList.push(payload);
  // },
  // clearAssessmentRatings(state) {
  //   state.assessmentRatingsList = [];
  // },
  // setCampusAdaptationMeasures(state, payload) {
  //   state.campusAdaptationMeasuresList.push(payload);
  // },
  // clearCampusAdaptationMeasures(state) {
  //   state.campusAdaptationMeasuresList = [];
  // },
  // setRegionAdaptationMeasures(state, payload) {
  //   state.regionAdaptationMeasuresList.push(payload);
  // },
  // clearRegionAdaptationMeasures(state) {
  //   state.regionAdaptationMeasuresList = [];
  // },
  // setCampusCoreAdaptationActions(state, payload) {
  //   state.campusCoreAdaptationActionsList.push(payload);
  // },
  // clearCampusCoreAdaptationActions(state) {
  //   state.campusCoreAdaptationActionsList = [];
  // },
  // setRegionCoreAdaptationActions(state, payload) {
  //   state.regionCoreAdaptationActionsList.push(payload);
  // },
  // clearRegionCoreAdaptationActions(state) {
  //   state.regionCoreAdaptationActionsList = [];
  // },
  // setCurrentRegion(state, payload) {
  //   state.currentRegion = payload;
  // },
};

const actions = {
  // fetchCampuses({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/campuses";
  //       axios.get(url).then((result) => {
  //         commit("clearCampuses");
  //         result.data.forEach((item) => {
  //           commit("setCampuses", item);
  //         });
  //         commit("setCurrentCampus", result.data[0].code);
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchEnterprises({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "enterprise-risks";
  //       axios.get(url).then((result) => {
  //         commit("setEnterpriseList", result.data);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchPortfolio({ commit, dispatch }, payload) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "portfolio-risks";
  //       let data = {
  //         enterprise_risk_id: payload.id,
  //       };
  //       let config = {
  //         responseType: "json",
  //       };
  //       axios.get(url, { params: data }, config).then((result) => {
  //         const newArr = result.data.map((item) => ({
  //           id: item.id,
  //           risk_statement: item.risk_statement,
  //         }));
  //         commit("setPortfolioList", newArr);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchSubAssetTypes({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/sub-asset-types";
  //       axios.get(url).then((result) => {
  //         commit("clearSubAssetTypes");
  //         result.data.forEach((item) => {
  //           commit("setSubAssetTypes", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAdaptationTypes({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "adaptationTypes";
  //       axios.get(url).then((result) => {
  //         commit("clearAdaptationTypes");
  //         result.data.forEach((item) => {
  //           commit("setAdaptationTypes", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAdaptationCategories({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "adaptationCategories";
  //       axios.get(url).then((result) => {
  //         commit("clearAdaptationCategories");
  //         result.data.forEach((item) => {
  //           commit("setAdaptationCategories", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAdaptationCobenefit({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "adaptationCobenefit";
  //       axios.get(url).then((result) => {
  //         commit("clearAdaptationCobenefit");
  //         result.data.forEach((item) => {
  //           commit("setAdaptationCobenefit", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchClimateHazards({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/climate-variables";
  //       axios.get(url).then((result) => {
  //         commit("clearClimateHazards");
  //         result.data.forEach((item) => {
  //           commit("setClimateHazards", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchConsequenceCategories({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/consequence-areas";
  //       axios.get(url).then((result) => {
  //         commit("clearConsequenceCategories");
  //         result.data.forEach((item) => {
  //           commit("setConsequenceCategories", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchRiskTypes({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/risk-types";
  //       axios.get(url).then((result) => {
  //         commit("clearRiskTypes");
  //         result.data.forEach((item) => {
  //           commit("setRiskTypes", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchRegions({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/regions";
  //       axios.get(url).then((result) => {
  //         commit("clearRegions");
  //         result.data.forEach((item) => {
  //           commit("setRegions", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAssessmentConsequences({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/climate-risk-consequences";
  //       axios.get(url).then((result) => {
  //         commit("clearAssessmentConsequences");
  //         result.data.forEach((item) => {
  //           commit("setAssessmentConsequences", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAssessmentLikelihood({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/climate-risk-likelihoods";
  //       axios.get(url).then((result) => {
  //         commit("clearAssessmentLikelihood");
  //         result.data.forEach((item) => {
  //           commit("setAssessmentLikelihood", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchAssessmentRatings({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "general/climate-risk-ratings";
  //       axios.get(url).then((result) => {
  //         commit("clearAssessmentRatings");
  //         result.data.forEach((item) => {
  //           commit("setAssessmentRatings", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchCampusAdaptationMeasures({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "adaptationMeasures";
  //       const data = {
  //         params: { levelCode: "campus" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit("clearCampusAdaptationMeasures");
  //         result.data.forEach((item) => {
  //           commit("setCampusAdaptationMeasures", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchRegionAdaptationMeasures({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "adaptationMeasures";
  //       const data = {
  //         params: { levelCode: "region" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit("clearRegionAdaptationMeasures");
  //         result.data.forEach((item) => {
  //           commit("setRegionAdaptationMeasures", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchCampusCoreAdaptationActions({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "getCoreAdaptationActions";
  //       const data = {
  //         params: { levelCode: "campus" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit("clearCampusCoreAdaptationActions");
  //         result.data.forEach((item) => {
  //           commit("setCampusCoreAdaptationActions", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // fetchRegionCoreAdaptationActions({ commit, dispatch }) {
  //   return new Promise(function (resolve, reject) {
  //     try {
  //       let url = baseApiUrl + "getCoreAdaptationActions";
  //       const data = {
  //         params: { levelCode: "region" },
  //       };
  //       axios.get(url, data).then((result) => {
  //         commit("clearRegionCoreAdaptationActions");
  //         result.data.forEach((item) => {
  //           commit("setRegionCoreAdaptationActions", item);
  //         });
  //         resolve(true);
  //       });
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });
  // },
  // // updateCRAEntry({state, commit, dispatch}, payload) {
  // //   return new Promise(function(resolve, reject){
  // //       let url = baseApiUrl + "campus-risks"
  // //       let data = {
  // //         id: payload.id,
  // //         subAssetTypeCode: payload.subAssetTypeCode,
  // //         climateHazardCode: payload.climateHazardCode,
  // //         riskTypeCode: payload.riskTypeCode,
  // //         consequenceCategoryCode: payload.consequenceCategoryCode,
  // //         risk_statement: payload.risk_statement,
  // //         assumed_controls: payload.assumed_controls,
  // //         criticalCampuses: payload.critical_campuses,
  // //       }
  // //       let config  = {
  // //           responseType: 'json'
  // //       }
  // //       axios.post(url, data, config).then(result=>{
  // //           resolve();
  // //       })
  // //   })
  // // },
  // updateCRAEntry({state, commit, dispatch}, payload) {
  //   return new Promise(function(resolve, reject){
  //       let url = baseApiUrl + "campus-risks"
  //       let data = {
  //         id: payload.id,
  //         statement: payload.risk_statement,
  //         consequenceAreas: payload.consequenceCategoryCode,
  //         riskType: payload.riskTypeCode,
  //         stConsequence: payload.stConsequenceCode,
  //         stLikelihood: payload.stLikelihoodCode,
  //         stRating: payload.stRiskRatingCode,
  //         ltConsequence: payload.ltConsequenceCode,
  //         ltLikelihood: payload.ltLikelihoodCode,
  //         ltRating: payload.ltRiskRatingCode,
  //         subAssetType: payload.subAssetTypeCode,
  //         climateVariables: payload.climateHazardCode,
  //         assumedControls: payload.assumed_controls,
  //         region: payload.region,
  //         campus: payload.campus,
  //         // criticalCampuses: payload.critical_campuses,
  //         // treatmentSTLikelihoodCode: payload.treatmentRiskAssessmentLikelihoodSTCode,
  //         // treatmentSTConsequenceCode: payload.treatmentRiskAssessmentConsequenceSTCode,
  //         // treatmentSTRiskRatingCode: payload.treatmentRiskRatingSTCode,
  //         // treatmentLTLikelihoodCode: payload.treatmentRiskAssessmentLikelihoodLTCode,
  //         // treatmentLTConsequenceCode: payload.treatmentRiskAssessmentConsequenceLTCode,
  //         // treatmentLTRiskRatingCode: payload.treatmentRiskRatingLTCode,
  //       }
  //       let config  = {
  //           responseType: 'json'
  //       }
  //       axios.put(url, data, config).then(result=>{
  //           resolve();
  //       })
  //   })
  // },
  // updateRiskAdaptationCRAEntry({ state, commit, dispatch }, payload) {
  //   return new Promise(function (resolve, reject) {
  //     let url = baseApiUrl + "updateRiskAdaptationActionEntry";
  //     let data = {
  //       id: payload.id,
  //       adaptationActionTypeCode: payload.adaptationActionTypeCode,
  //       adaptationActionTypeCode: payload.adaptationActionTypeCode,
  //       likelihoodCode: payload.likelihoodCode,
  //       consequenceCode: payload.consequenceCode,
  //       riskRatingCode: payload.riskRatingCode,
  //     };
  //     let config = {
  //       responseType: "json",
  //     };
  //     axios.post(url, data, config).then((result) => {
  //       resolve();
  //     });
  //   });
  // },
  // deleteCRAEntry({ state, commit, dispatch }, payload) {
  //   return new Promise(function (resolve, reject) {
  //     let url = baseApiUrl + "campus-risks";
  //     let data = {
  //       id: payload.id,
  //     };
  //     let config = {
  //       responseType: "json",
  //     };
  //     axios.delete(url, { params: data }, config).then((result) => {
  //       resolve();
  //     });
  //   });
  // },
  // saveCRAEntry({state, commit, dispatch}, payload) {
  //   return new Promise(function(resolve, reject){
  //       let url = baseApiUrl + "campus-risks"
  //       let data = {
  //         statement: payload.riskStatement,
  //         consequenceAreas: payload.consequenceCategoryCodes.join(", "),
  //         riskType: payload.riskTypeCode,
  //         stConsequence: payload.riskAssessmentConsequenceSTCode,
  //         stLikelihood: payload.riskAssessmentLikelihoodSTCode,
  //         stRating: payload.riskAssessmentRatingSTCode,
  //         ltConsequence: payload.riskAssessmentConsequenceLTCode,
  //         ltLikelihood: payload.riskAssessmentLikelihoodLTCode,
  //         ltRating: payload.riskAssessmentRatingLTCode,
  //         region: payload.regionCode,
  //         campus: payload.campusCode,
  //         subAssetType: payload.subAssetTypeCode,
  //         climateVariables: payload.climateHazardCodes.join(", "),
  //         assumedControls: payload.assumedControls,
  //         portfolioRiskID: payload.portfolioCode.id,

  //         ntVulnerability: 0,
  //         ntRiskScore: 0,
  //         ltVulnerability: 0,
  //         ltRiskScore: 0,
  //         impact: 0,
  //         riskScoreGreaterThan7_5: "riskScoreGreaterThan7_5"
  //       }
  //       let config  = {
  //           responseType: 'json'
  //       }
  //       axios.post(url, data, config).then(result=>{
  //           resolve();
  //       })
  //   })
  // },
  // saveAdaptationActionEntry({ state, commit, dispatch }, payload) {
  //   return new Promise(function (resolve, reject) {
  //     let url = baseApiUrl + "saveAdaptationActionDetailsEntry";
  //     let data = {
  //       craAdaptationActionDetailsId: payload.craAdaptationActionDetailsId,
  //       craId: payload.craID,
  //       climateHazardCode: payload.climateHazardCode,
  //       assetLevel2Code: payload.assetLevel2Code,
  //       measureCode: payload.measureCode,
  //       measureDesc: payload.measureDesc,
  //       feasibility: payload.feasibility,
  //       indicativeCost: payload.indicativeCost,
  //       impact: payload.impact,
  //       mean: payload.mean,
  //       adaptationCategoryCode: payload.adaptationCategoryCode,
  //       adaptationTypeCode: payload.adaptationTypeCode,
  //       isImplemented: payload.isImplemented,
  //       ownerResponsible: payload.ownerResponsible,
  //       implementationTiming: payload.implementationTiming,
  //       timeline: payload.timeline,
  //       adaptationControl: payload.adaptationControl,
  //     };
  //     let config = {
  //       responseType: "json",
  //     };
  //     axios.post(url, data, config).then((result) => {
  //       resolve();
  //     });
  //   });
  // },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
