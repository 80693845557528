import actions from "./actions.js";
import mutations from "./mutations.js";
import getters from "./getters.js";

const state = {
  currentCampus: null,
  // currentCRAList: [],
  campusList: [],
  enterpriseList: [],
  portfolioList: [],
  adaptationTypesList: [],
  subAssetTypesList: [],
  adaptationTimingsList: [],
  adaptationCategoriesList: [],
  adaptationCobenefitList: [],
  climateHazardsList: [],
  consequenceCategoriesList: [],
  riskTypesList: [],
  regionsList: [],
  assessmentConsequencesList: [],
  assessmentLikelihoodList: [],
  assessmentRatingsList: [],
  campusAdaptationMeasuresList: [],
  // regionAdaptationMeasuresList: [],
  // campusCoreAdaptationActionsList: [],
  // regionCoreAdaptationActionsList: [],
  currentRegion: null,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
