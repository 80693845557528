<template>
    <el-tag class="ml-2" type="danger" effect="dark" v-if="code == 'very_high'">{{ label }}</el-tag>
    <el-tag class="ml-2" type="danger" v-if="code == 'high'">{{ label }}</el-tag>
    <el-tag class="ml-2" type="warning" effect="dark" v-if="code == 'moderate'">{{ label }}</el-tag>
    <el-tag class="ml-2" type="success" v-if="code == 'low'">{{ label }}</el-tag>
    <el-tag class="ml-2" type="success" effect="dark" v-if="code == 'very_low'">{{ label }}</el-tag>
</template>
<script>
export default {
    name: "RiskRating",
    props: ["code", "label"]
}
</script>