export const climateRiskMixin = {
  methods: {
    computeRiskRatingForm: function (item, term) {
      const likelihoodCode = "riskAssessmentLikelihood" + term + "Code";
      const consequenceCode = "riskAssessmentConsequence" + term + "Code";
      const ratingCode = "riskAssessmentRating" + term + "Code";
      const likelihoodLabel = "riskAssessmentLikelihood" + term + "Label";
      const consequenceLabel = "riskAssessmentConsequence" + term + "Label";
      const ratingLabel = "riskAssessmentRating" + term + "Label";
      item[likelihoodCode] = item[likelihoodLabel]
        .replace(" ", "_")
        .toLowerCase();
      item[consequenceCode] = item[consequenceLabel]
        .replace(" ", "_")
        .toLowerCase();
      if (item[likelihoodCode] && item[consequenceCode]) {
        if (item[likelihoodCode] == "almost_certain") {
          if (["major", "severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_high") {
              item[ratingCode] = "very_high";
              item[ratingLabel] = "Very High";
            }
          } else if (["minor", "medium"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "rare") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else if (["negligible", "minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "unlikely") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "likely") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_high") {
              item[ratingCode] = "very_high";
              item[ratingLabel] = "Very High";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "possible") {
          if (["medium", "major", "severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        }
      } else {
        if (item[ratingCode]) {
          item[ratingCode] = "";
          item[ratingLabel] = "";
        }
      }
      return item;
    },
    computeRiskRatingList: function (item, ratingType, term) {
      const type = ratingType.replace(" ", "_");
      const likelihoodCode = term + "_" + type + "_likelihood_code";
      const consequenceCode = term + "_" + type + "_consequence_code";
      const ratingCode = term + "_" + type + "_rating_code";
      const ratingLabel = term + "_" + type + "_rating_label";

      if (item[likelihoodCode] && item[consequenceCode]) {
        if (item[likelihoodCode] == "almost_certain") {
          if (["major", "severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_high") {
              item[ratingCode] = "very_high";
              item[ratingLabel] = "Very High";
            }
          } else if (["minor", "medium"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "rare") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else if (["negligible", "minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "unlikely") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "likely") {
          if (["severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "very_high") {
              item[ratingCode] = "very_high";
              item[ratingLabel] = "Very High";
            }
          } else if (["medium", "major"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        } else if (item[likelihoodCode] == "possible") {
          if (["medium", "major", "severe"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "high") {
              item[ratingCode] = "high";
              item[ratingLabel] = "High";
            }
          } else if (["minor"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "moderate") {
              item[ratingCode] = "moderate";
              item[ratingLabel] = "Moderate";
            }
          } else if (["negligible"].includes(item[consequenceCode])) {
            if (item[ratingCode] != "low") {
              item[ratingCode] = "low";
              item[ratingLabel] = "Low";
            }
          } else {
            if (item[ratingCode] != "very_low") {
              item[ratingCode] = "very_low";
              item[ratingLabel] = "Very Low";
            }
          }
        }
      } else {
        if (item[ratingCode]) {
          item[ratingCode] = "";
          item[ratingLabel] = "";
        }
      }
      return item;
    },
  },
};
