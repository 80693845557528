<template>
  <el-collapse>
    <el-collapse-item>
      <template #title>
        <el-text class="arc-orange-font" size="small"><el-icon><SetUp /></el-icon> Table Advance Filters</el-text>
      </template>
      <el-divider border-style="dashed"/>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Short Term Likelihood</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-select
              v-model="advanceFilters.selectedSTLikelihood"
              @change="e => handleOnChangeFilter('selectedSTLikelihood', e)"
              placeholder="Short Term Likelihood"
              filterable
              clearable
              @clear="resetFilterToAll('selectedSTLikelihood')"
            >
              <el-option
                v-for="item in assessmentLikelihoodFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Short Term Consequence</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space wrap fill>
            <el-select
              v-model="advanceFilters.selectedSTConsequence"
              @change="e => handleOnChangeFilter('selectedSTConsequence', e)"
              placeholder="Short Term Consequence"
              filterable
              clearable
              @clear="resetFilterToAll('selectedSTConsequence')"
            >
              <el-option
                v-for="item in assessmentConsequenceFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Short Term Risk Rating</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space wrap fill>
            <el-select
              v-model="advanceFilters.selectedSTRating"
              @change="e => handleOnChangeFilter('selectedSTRating', e)"
              placeholder="Default (All)"
              filterable
              clearable
              multiple
              @clear="resetFilterToAll('selectedSTRating')"
              >
              <el-option
                v-for="item in assessmentRatingFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt-3">
        <el-col :span="4">
          <el-space wrap fill>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Long Term Likelihood</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space wrap fill>
            <el-select
              v-model="advanceFilters.selectedLTLikelihood"
              @change="e => handleOnChangeFilter('selectedLTLikelihood', e)"
              placeholder="Long Term Likelihood"
              filterable
              clearable
              @clear="resetFilterToAll('selectedLTLikelihood')"
            >
              <el-option
                v-for="item in assessmentLikelihoodFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Long Term Consequence</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space wrap fill>
            <el-select
              v-model="advanceFilters.selectedLTConsequence"
              @change="e => handleOnChangeFilter('selectedLTConsequence', e)"
              placeholder="Long Term Consequence"
              filterable
              clearable
              @clear="resetFilterToAll('selectedLTConsequence')"
            >
              <el-option
                v-for="item in assessmentConsequenceFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Long Term Risk Rating</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space wrap fill>
            <el-select
              v-model="advanceFilters.selectedLTRating"
              @change="e => handleOnChangeFilter('selectedLTRating', e)"
              placeholder="Default (All)"
              filterable
              clearable
              multiple
              @clear="resetFilterToAll('selectedLTRating')"
            >
              <el-option
                v-for="item in assessmentRatingFilterSection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="5">
          <el-space wrap>
          </el-space>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt-3">
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">TAFE NSW Consequence Area</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-select
              v-model="advanceFilters.selectedConsequence"
              @change="e => handleOnChangeFilter('selectedConsequence', e)"
              placeholder="TAFE NSW Consequence Area"
              filterable
              clearable
              @clear="resetFilterToAll('selectedConsequence')"
            >
              <el-option
                v-for="item in consequenceFilterSelection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-text
              v-if="type !== 'region'"
              size="small"
              class="mx-1 arc-primary-font"
              tag="b"
            >
              Risk Type
            </el-text>
            <el-text
              v-else
              size="small"
              class="mx-1 arc-primary-font"
              tag="b"
            >
              Critical Campuses
            </el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-select
              v-model="advanceFilters.selectedRiskType"
              :key="advanceFilters.selectedRiskType"
              @change="e => handleOnChangeFilter('selectedRiskType', e)"
              placeholder="Risk Type"
              filterable
              clearable
              @clear="resetFilterToAll('selectedRiskType')"
            >
              <el-option
                v-for="item in riskFilterSelection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
        <el-col :span="4" v-if="type !== 'enterprise'">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Asset Level 2</el-text>
          </el-space>
        </el-col>
        <el-col :span="4" v-if="type !== 'enterprise'">
          <el-space fill wrap>
            <el-select
              v-model="advanceFilters.selectedAssetLevel2"
              @change="e => handleOnChangeFilter('selectedAssetLevel2', e)"
              placeholder="Asset Level 2"
              filterable
              clearable
              @clear="resetFilterToAll('selectedAssetLevel2')"
            >
              <el-option
                v-for="item in assetLevel2FilterSelection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="mt-3" v-if="type !== 'enterprise'">
        <el-col :span="4">
          <el-space fill wrap>
            <el-text size="small" class="mx-1 arc-primary-font" tag="b">Climate Hazard</el-text>
          </el-space>
        </el-col>
        <el-col :span="4">
          <el-space fill wrap>
            <el-select
              v-model="advanceFilters.selectedClimateHazard"
              @change="e => handleOnChangeFilter('selectedClimateHazard', e)"
              placeholder="Climate Hazard"
              filterable
              clearable
              @clear="resetFilterToAll('selectedClimateHazard')"
            >
              <el-option
                v-for="item in climateHazardFilterSelection"
                :key="item.code"
                :label="item.label"
                :value="item.code"
              />
            </el-select>
          </el-space>
        </el-col>
      </el-row>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
/*eslint-disable */
import { SetUp } from '@element-plus/icons-vue'

export default {
  name: 'AssessmentFilter',
  props: ["initialAdvanceFilters", "type"],
  emits: ["changeFilter"],
  components: { SetUp },
  computed: {
    advanceFilters: {
      get() {
        return structuredClone(this.initialAdvanceFilters)
      }
    },
    assessmentConsequenceFilterSection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.assessmentConsequencesList]
      }
    },
    assessmentLikelihoodFilterSection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.assessmentLikelihoodList]
      }
    },
    assessmentRatingFilterSection: {
      get() {
        return [...this.$store.state.climateRiskModule.assessmentRatingsList]
      }
    },
    assetLevel2FilterSelection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.subAssetTypesList]
      }
    },
    consequenceFilterSelection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.consequenceCategoriesList]
      }
    },
    climateHazardFilterSelection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.climateHazardsList]
      }
    },
    riskFilterSelection: {
      get() {
        return [{ code: "all", label: "All" }, ...this.$store.state.climateRiskModule.riskTypesList]
      }
    },
  },
  methods: {
    handleOnChangeFilter: function(field, value) {
      this.$emit("changeFilter", { field, value })
    },
    resetFilterToAll: function(field) {
      this.$emit("changeFilter", { field, value: 'all' })
    }
  },
}
</script>

<style scoped>
.arc-primary-font{
  color: #0C0808
}

.filter-label {
  color: orange;
  font-weight: bold;
}
.arc-orange-font{
  color: #E4610F
}

.el-collapse {
  border: 0;
}
</style>
  