import { createStore } from "vuex";
import mainStateModule from "./mainStateModule.js"
import mapStateModule from "./mapStateModule.js"
import climateRiskModule from "./climateRiskModule"

export const store = createStore({
  modules: {
    mapModule: mapStateModule,
    mainModule: mainStateModule,
    climateRiskModule
  }
});