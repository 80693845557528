<template>
    <el-table :data="dataSource" border style="width: 100%" header-cell-class-name="custom-tbl-header">
    <el-table-column :label="`Total number of risks: ${selectedRow.child_risk_count}`" width="200">
      <template #default="{ $index }">
        <div>{{ $index === 0 ? 'Risk profile' : $index === 1 ? 'Risks by Hazard' : 'Risks by Asset Level 2' }}</div>
      </template>
    </el-table-column>
    <el-table-column label="Short term">
      <template #default="{ $index }">
        <div v-if="$index === 0" class= "small-chart">
          <DoughnutChart
            chartId="relatedRisksSTChart"
            :chartData="relatedRisksSTData"
            :chartOptions="relatedRisksOptions"
          />
        </div>
        <div v-else-if="$index === 1 && type !== 'enterprise'" class= "small-chart">
          <BarChart
            chartId="relatedRisksSTClimateVariableChart"
            :chartData="relatedRisksSTClimateVariableData"
            :chartOptions="relatedRisksClimateVariableOptions"
          />
        </div>
        <div v-else-if="$index === 2 && type !== 'enterprise'" class= "small-chart">
          <BarChart
            chartId="relatedRisksSTAssetLevel2Chart"
            :chartData="relatedRisksSTAssetLevel2Data"
            :chartOptions="relatedRisksAssetLevel2Options"
          />
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Long term">
      <template #default="{ $index }">
        <div v-if="$index === 0" class= "small-chart">
          <DoughnutChart
            chartId="relatedRisksLTChart"
            :chartData="relatedRisksLTData"
            :chartOptions="relatedRisksOptions"
          />
        </div>
        <div v-else-if="$index === 1 && type !== 'enterprise'" class= "small-chart">
          <BarChart
            chartId="relatedRisksLTClimateVariableChart"
            :chartData="relatedRisksLTClimateVariableData"
            :chartOptions="relatedRisksClimateVariableOptions"
          />
        </div>
        <div v-else-if="$index === 2 && type !== 'enterprise'" class= "small-chart">
          <BarChart
            chartId="relatedRisksLTAssetLevel2Chart"
            :chartData="relatedRisksLTAssetLevel2Data"
            :chartOptions="relatedRisksAssetLevel2Options"
          />
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
/*eslint-disable */
import BarChart from './charts/BarChart.vue';
import DoughnutChart from './charts/DoughnutChart.vue';

export default {
  name: 'ChildRiskSummary',
  components: {
    BarChart,
    DoughnutChart,
  },
  emits: [],
  props: ['selectedRow', 'type'],
  mixins: [],
  data() {
    return {
      windowHeight: 0,
      relatedRisksOptions: {
        plugins: {
          title: {
            text: `Summary of related ${this.type === 'enterprise' ? 'portfolio' : 'campus'} risks`,
            display: true,
            font: {
              family: 'Segoe UI',
              size: 15,
              weight: 'bold',
              lineHeight: 1.2,
            },
          },
          legend: {
            display: true,
            position: 'left',
            labels: {
              fontStyle: 'bold',
              fontFamily: 'Segoe UI'
            }
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function(tooltipItem) {
                const value = tooltipItem.raw
                const total = tooltipItem?.dataset.data.reduce(function(previousValue, currentValue) {
                  return previousValue + currentValue;
                });
                if (!total) {
                  return '0%'
                }
                const percentage = value / total * 100
                return `${tooltipItem.label}: ${Math.round((percentage + Number.EPSILON) * 100) / 100}%`;
              }
            }
          },
          maintainAspectRatio: true,
          responsive: true,
        }
      },
      relatedRisksClimateVariableOptions: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            text: "Risk ratings by climate hazard",
            display: true,
            font: {
              family: "Segoe UI",
              size: 15,
              weight: "bold",
              lineHeight: 1.2,
            },
          },
          legend: {
            position: "left",
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          }
        },
        maintainAspectRatio: false,
      },
      relatedRisksAssetLevel2Options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
          },
          y: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            text: "Risk ratings by climate hazard",
            display: true,
            font: {
              family: "Segoe UI",
              size: 15,
              weight: "bold",
              lineHeight: 1.2,
            },
          },
          legend: {
            position: "left",
            labels: {
              boxWidth: 10,
              font: {
                size: 9
              }
            }
          }
        },
        maintainAspectRatio: false,
      },
    }
  },
  computed: {
    dataSource() {
      return this.type === 'enterprise' ? [{}] : [{}, {}, {}]
    },
    relatedRisksSTDataSet() {
      const backgroundColor = ['#46D267', '#28A745', '#FFC107', '#FF9900', '#DD4B39', '#6C757D']

      // data arrangement is
      // 'very_low': 0,
      // 'low': 3,
      // 'moderate': 1,
      // 'high': 0,
      // 'very_high': 0,
      // 'no_rating': 1
      const data = this.selectedRow.st_child_risk_summary

      return [
        {
          backgroundColor,
          data: Object.values(data).map(val => val),
        },
      ]
    },
    relatedRisksSTData() {
      return {
        labels: [
          'Very Low',
          'Low',
          'Moderate',
          'High',
          'Very High',
          'No Rating',
        ],
        datasets: this.relatedRisksSTDataSet
      }
    },
    relatedRisksLTDataSet() {
      const backgroundColor = ['#46D267', '#28A745', '#FFC107', '#FF9900', '#DD4B39', '#6C757D']

      // data arrangement is
      // 'very_low': 0,
      // 'low': 3,
      // 'moderate': 1,
      // 'high': 0,
      // 'very_high': 0,
      // 'no_rating': 1
      const data = this.selectedRow.lt_child_risk_summary

      return [
        {
          backgroundColor,
          data: Object.values(data).map(val => val),
        },
      ]
    },
    relatedRisksLTData() {
      return {
        labels: [
          'Very Low',
          'Low',
          'Moderate',
          'High',
          'Very High',
          'No Rating',
        ],
        datasets: this.relatedRisksLTDataSet
      }
    },
    relatedRisksSTClimateVariableDataSet() {
      return [
        { label: "No Rating", data: this.selectedRow.st_climate_variable_summary.no_rating, backgroundColor: "#6C757D" },
        { label: "Very Low", data: this.selectedRow.st_climate_variable_summary.very_low, backgroundColor: "#46D267" },
        { label: "Low", data: this.selectedRow.st_climate_variable_summary.low, backgroundColor: "#28A745" },
        { label: "Moderate", data: this.selectedRow.st_climate_variable_summary.moderate, backgroundColor: "#FFC107" },
        { label: "High", data: this.selectedRow.st_climate_variable_summary.high, backgroundColor: "#FF9900" },
        { label: "Very High", data: this.selectedRow.st_climate_variable_summary.very_high, backgroundColor: "#DD4B39" },
      ]
    },
    relatedRisksSTClimateVariableData() {
      return {
        labels: [
          'Bushfire',
          'Drought',
          'Flooding',
          'Heatwaves',
          'Increased Rainfall Intensity',
          'Sea Level Rise',
          'Storms',
          'Extreme Heat',
        ],
        datasets: this.relatedRisksSTClimateVariableDataSet
      }
    },
    relatedRisksLTClimateVariableDataSet() {
      return [
        { label: "No Rating", data: this.selectedRow.lt_climate_variable_summary.no_rating, backgroundColor: "#6C757D" },
        { label: "Very Low", data: this.selectedRow.lt_climate_variable_summary.very_low, backgroundColor: "#46D267" },
        { label: "Low", data: this.selectedRow.lt_climate_variable_summary.low, backgroundColor: "#28A745" },
        { label: "Moderate", data: this.selectedRow.lt_climate_variable_summary.moderate, backgroundColor: "#FFC107" },
        { label: "High", data: this.selectedRow.lt_climate_variable_summary.high, backgroundColor: "#FF9900" },
        { label: "Very High", data: this.selectedRow.lt_climate_variable_summary.very_high, backgroundColor: "#DD4B39" },
      ]
    },
    relatedRisksLTClimateVariableData() {
      return {
        labels: [
          'Bushfire',
          'Drought',
          'Flooding',
          'Heatwaves',
          'Increased Rainfall Intensity',
          'Sea Level Rise',
          'Storms',
          'Extreme Heat',
        ],
        datasets: this.relatedRisksLTClimateVariableDataSet
      }
    },
    relatedRisksSTAssetLevel2DataSet() {
      return [
        { label: "No Rating", data: this.selectedRow.st_sub_asset_summary.no_rating, backgroundColor: "#6C757D" },
        { label: "Very Low", data: this.selectedRow.st_sub_asset_summary.very_low, backgroundColor: "#46D267" },
        { label: "Low", data: this.selectedRow.st_sub_asset_summary.low, backgroundColor: "#28A745" },
        { label: "Moderate", data: this.selectedRow.st_sub_asset_summary.moderate, backgroundColor: "#FFC107" },
        { label: "High", data: this.selectedRow.st_sub_asset_summary.high, backgroundColor: "#FF9900" },
        { label: "Very High", data: this.selectedRow.st_sub_asset_summary.very_high, backgroundColor: "#DD4B39" },
      ]
    },
    relatedRisksSTAssetLevel2Data() {
      return {
        labels: [
          'Conveying',
          'Electrical',
          'Exterior Enclosure',
          'Fire Protection',
          'HVAC',
          'Interior Construction',
          'Plumbing',
          'Site Electrical Utilities',
          'Site Improvement',
          'Superstructure',
        ],
        datasets: this.relatedRisksSTAssetLevel2DataSet
      }
    },
    relatedRisksLTAssetLevel2DataSet() {
      return [
        { label: "No Rating", data: this.selectedRow.lt_sub_asset_summary.no_rating, backgroundColor: "#6C757D" },
        { label: "Very Low", data: this.selectedRow.lt_sub_asset_summary.very_low, backgroundColor: "#46D267" },
        { label: "Low", data: this.selectedRow.lt_sub_asset_summary.low, backgroundColor: "#28A745" },
        { label: "Moderate", data: this.selectedRow.lt_sub_asset_summary.moderate, backgroundColor: "#FFC107" },
        { label: "High", data: this.selectedRow.lt_sub_asset_summary.high, backgroundColor: "#FF9900" },
        { label: "Very High", data: this.selectedRow.lt_sub_asset_summary.very_high, backgroundColor: "#DD4B39" },
      ]
    },
    relatedRisksLTAssetLevel2Data() {
      return {
        labels: [
          'Conveying',
          'Electrical',
          'Exterior Enclosure',
          'Fire Protection',
          'HVAC',
          'Interior Construction',
          'Plumbing',
          'Site Electrical Utilities',
          'Site Improvement',
          'Superstructure',
        ],
        datasets: this.relatedRisksLTAssetLevel2DataSet
      }
    },
  },
  watch: {},
  methods: {},
  mounted() {
    this.windowHeight = window.innerHeight
    window.onresize = () => {
      this.windowHeight = window.innerHeight
    }
  },
}
</script>

<style scoped>
.small-chart {
  max-width: 300px;
  margin:  8px auto;
}

::v-deep .custom-tbl-header {
  color: #E4610F !important;
  background-color: #f5f7fa !important;
}
</style>