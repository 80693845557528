<template>
  <div style="width: 100%;">
    <el-row :span="24">
      <el-scrollbar max-height="95dvh" style="width: 100%;">
        <el-main style="width: 100%;">
          <el-row :gutter="12" class="mb-2">
            <el-col>
              <el-card shadow="always">
                <el-text tag="b" class="arc-primary-font" size="large">
                  RAAP Components
                </el-text>
              </el-card>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10" class="pe-2">
              <el-card shadow="always" class="landing-page-map">
                <LandingPageMap />
              </el-card>
            </el-col>
            <el-col :span="14">
              <el-row :gutter="12">
                <el-col>
                  <el-card shadow="always" style="width: 100%">
                    <el-text tag="p" class="arc-primary-font" size="large">
                      The Risk and Adaptation Platform provides three key components at each assessment scale
                      <br>
                      (Enterprise, Portfolio and Campus):

                    </el-text>
                    <div style="text-align: center;">
                      <el-image src="HomePageDiagram.png" style="height: 270px" />
                    </div>
                  </el-card>
                </el-col>
              </el-row>

              <el-row :gutter="12" class="mt-3">
                <el-col :span="8" v-for="item in raapComponents" :key=item.id>
                  <el-card shadow="always">
                    <el-row :gutter="20">
                      <el-col :span="4">
                        <el-avatar :size="55" class="arc-light-orange-bg">
                          <el-icon size="30">
                            <component :is="item.icon_src"></component>
                          </el-icon>
                        </el-avatar>
                      </el-col>
                      <el-col :span="16">
                        <el-text tag="b" size="large" class="arc-orange-font">
                          <h4 class="pt-3 ps-1">{{ item.label }}</h4>
                        </el-text>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="4">
                      </el-col>
                      <el-col :span="16">
                        <el-image style="width: 100%; height: 20vh" :src="item.id + '_menu.jpg'" fit="fill" />
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="16">
                      </el-col>
                      <el-col :span="4" class="mt-3">
                        <el-button type="warning" size="large" @click="reroute(item.id)" round>VIEW</el-button>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
          </el-row>

        </el-main>
      </el-scrollbar>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable */
import {
  Suitcase,
  LocationInformation,
  School
} from '@element-plus/icons-vue'
import { ref } from 'vue'
import LandingPageMap from './LandingPageMap.vue'

export default {
  name: 'Home',
  components: {
    Suitcase,
    LocationInformation,
    School,
    LandingPageMap,
  },
  data() {
    return {
      activeAboutAccordion: ref('1'),
      raapComponents: [
        {
          id: "enterprise",
          label: "ENTERPRISE",
          icon_src: LocationInformation,
        },
        {
          id: "portfolio",
          label: "PORTFOLIO",
          icon_src: Suitcase,
        },
        {
          id: "campus",
          label: "CAMPUS",
          icon_src: School,
        }
      ]
    }
  },
  methods: {
    reroute: function (item) {
      this.$store.commit('mainModule/setCurrentAssessment', item)
      this.$router.push({ path: '/' + item })
    }
  }
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}

.arc-orange-font {
  color: #E4610F
}

.arc-light-orange-font {
  color: #EB894B
}

.arc-primary-font {
  color: #0C0808
}

.image {
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
  height: 20vh;
  width: 20vw;
  display: block;
  border-radius: 1%;
}

.landing-page-map {
  width: 100%;
  height: 100%;
}

::v-deep .landing-page-map .el-card__body {
  height: 100%;
}
</style>