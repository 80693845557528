<template>
  <div id="app">
      <div class="headerClass">
        <router-view name="header"></router-view>
      </div>
      <div class="mainAreaClass">
        <router-view></router-view>
      </div>
  </div>
</template>



<script>
/*eslint-disable*/
import { loadModules } from 'esri-loader';

export default {
  name: 'App',
  components: {
  },
  // store: store,
  data() {
    return {
        portalURL: "https://aus.gis.arcadis.com/portal",
        appId: '1dF0PrhdNvdD0E1Z',
    }
  },
  mounted () {
    const current = this
    
    loadModules([
        "esri/config",
        "esri/identity/IdentityManager",
        "esri/identity/OAuthInfo",
        "esri/portal/Portal"
    ],
    { css: true })
    .then(([
            esriConfig,
            esriId,
            OAuthInfo,
            Portal
        ]) => {
            esriConfig.portalUrl = this.portalURL
            var authInfo = new OAuthInfo({
                appId: this.appId,
                portalUrl: this.portalURL,
                popup: false
            });
            esriId.registerOAuthInfos([authInfo]);
            var portal = new Portal({
                url: this.portalURL
            });
            portal.authMode = "immediate";

            esriId
            .checkSignInStatus(current.portalURL + "/sharing/rest")
              .then(() => {
                // console.log("checkSignInStatus")
                current.$store.commit("mapModule/setESRILoginStatus", true)
              })
              .catch((e) => {
                // console.log("catch", e)
                current.$store.commit("mapModule/setESRILoginStatus", false)
              });

            portal.load().then(function(portalResult) {
                current.$store.commit("mapModule/setESRIPortalObject", portal)

                current.$store.dispatch("climateRiskModule/GET_ADAPTATION_TIMINGS")
                current.$store.dispatch("climateRiskModule/GET_ADAPTATION_TYPES")
                current.$store.dispatch("climateRiskModule/GET_ADAPTATION_CATEGORIES")
                current.$store.dispatch("climateRiskModule/GET_ADAPTATION_MEASURES")
                // current.$store.dispatch("climateRiskModule/GET_ADAPTATION_COBENEFIT")
                current.$store.dispatch("climateRiskModule/GET_ASSESSMENT_CONSEQUENCES")
                current.$store.dispatch("climateRiskModule/GET_ASSESSMENT_LIKELIHOOD")
                current.$store.dispatch("climateRiskModule/GET_ASSESSMENT_RATING")
                // current.$store.dispatch("climateRiskModule/GET_CAMPUS_ADAPTATION_MEASURES")
                // current.$store.dispatch("climateRiskModule/GET_CAMPUS_CORE_ADAPTATION_ACTIONS")
                current.$store.dispatch("climateRiskModule/GET_CAMPUSES")
                current.$store.dispatch("climateRiskModule/GET_CLIMATE_HAZARDS")
                current.$store.dispatch("climateRiskModule/GET_CONSEQUENCE_CATEGORIES")
                current.$store.dispatch("climateRiskModule/GET_ENTERPRISES")
                // current.$store.dispatch("climateRiskModule/GET_REGION_ADAPTATION_MEASURES")
                // current.$store.dispatch("climateRiskModule/GET_REGION_CORE_ADAPTATION_ACTIONS")
                current.$store.dispatch("climateRiskModule/GET_REGIONS")
                current.$store.dispatch("climateRiskModule/GET_RISK_TYPES")
                current.$store.dispatch("climateRiskModule/GET_SUB_ASSET_TYPES")
            });
            
        })
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: #2c3e50;
}
.headerClass{
  position: relative!important;;
}
.mainAreaClass {
  width: 100dvw!important;
  height: 95dvh!important;
  position: fixed !important;
  transition: margin-left 0.4s;
  
}
.esri-icon-non-visible::before {
  content: "\e610" !important;
}
.esri-icon-visible::before {
  content: "\e611" !important;
}
</style>
