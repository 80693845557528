<template>
  <el-scrollbar max-height="95dvh">
    <div class="common-layout">
      <el-container>
        <el-header>
          <el-row class="mt-2">
            <el-col>
              <el-card shadow="always"> 
                <el-row>
                  <el-col :span="1" class="custom-icon-space">
                    <el-avatar :size="30" class="arc-light-orange-bg"> <el-icon><LocationInformation /></el-icon> </el-avatar>
                  </el-col>
                  <el-col :span="23">
                    <el-breadcrumb separator="/" class="mt-2">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Region Assessment</el-breadcrumb-item>
                    </el-breadcrumb>
                  </el-col>
                </el-row>
                
              </el-card>
            </el-col>
          </el-row>
          
        </el-header>
        <el-main class="mt-2">
          <el-tabs type="border-card" tab-position="bottom" model-value="riskAndAdaptationRegisterTab">
            <el-tab-pane name="climateHazardTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-earth-americas"></i>
                  <el-text tag="b" class="arc-primary-font"> Climate Hazard Explorer</el-text>
                </span>
              </template>
              
              <iframe src="https://aus.gis.arcadis.com/portal/apps/dashboards/ab3f18b773a546d4ac29bacaacc1f5e3"
                class="custom-iframe-gis"></iframe>

            </el-tab-pane>
            <el-tab-pane name="riskModelDashboardTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-square-poll-vertical"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk Model Dashboard</el-text>
                </span>
              </template>
              
              <iframe :src="dashboardURL"
              class="custom-iframe-powerbi"
              ></iframe>

            </el-tab-pane>
            <el-tab-pane name="riskAndAdaptationRegisterTab">
              <template #label>
                <span class="arc-orange-font" >
                  <i class="fa-solid fa-table"></i>
                  <el-text tag="b" class="arc-primary-font"> Risk and Adaptation Register</el-text>
                </span>
              </template>
                <el-card shadow="never" class="mb-3">
                  <el-text class="mx-1 arc-primary-font" tag="b">
                    Select Region 
                    <el-icon><LocationInformation /></el-icon>
                  </el-text>
                  <el-select
                    v-model="selectedRegion"
                    placeholder="Select Region"
                    size="large"
                    filterable
                  >
                    <el-option
                      v-for="item in regionList"
                      :key="item.code"
                      :label="item.label"
                      :value="item.code"
                    />
                  </el-select>
                </el-card>
                <el-card shadow="never" class="mb-3">
                  <Dashboard
                    :initialCRAList="initialCRAList"
                  />
                </el-card>
                <el-card shadow="never" class="mb-3">
                  <AssessmentFilter
                    @changeFilter="handleOnChangeFilter"
                    :initialAdvanceFilters="advanceFilters"
                    type="region"
                  />
                </el-card>
                <el-tabs
                  v-model="activeRegisterTab"
                  tab-position="left"
                >
                  <el-tab-pane label="RISK" name="riskTab">
                    <RiskTable
                      @fetchCRA="handleOnFetchCRA"
                      :initialCRAList="initialCRAList"
                      :initialLoadstate="initialLoadstate"
                      type="region"
                    />
                  </el-tab-pane>
                  <el-tab-pane label="ADAPTATION" name="adaptationTab">
                    <AdaptationTable
                      @fetchCRA="handleOnFetchCRA"
                      :initialCRAList="initialCRAList"
                      :initialCampusLabel="initialRegionLabel"
                      :initialLoadstate="initialLoadstate"
                      type="region"
                    />
                  </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-main>
        <el-footer></el-footer>
      </el-container>
    </div>
  </el-scrollbar>
</template>

<script>
/*eslint-disable */
import AdaptationTable from './AdaptationTable.vue'
import AssessmentFilter from './AssessmentFilter.vue'
import Dashboard from './Dashboard.vue'
import RiskTable from './RiskTable.vue'
import axios from 'axios';
import { baseApiUrl } from "../../config.js"
import { ref } from 'vue'
import {
  LocationInformation,
  School,
  Suitcase,
} from '@element-plus/icons-vue'

export default {
  name: 'Region',
  components: {
    AdaptationTable,
    AssessmentFilter,
    Dashboard,
    RiskTable,
    LocationInformation,
    School,
    Suitcase,
  },
  data() {
    return {
      activeRegisterTab: ref('riskTab'),
      advanceFilters: {
        selectedAssetLevel2: "all",
        selectedClimateHazard: "all",
        selectedConsequence: "all",
        selectedRiskType: "all",
        selectedSTConsequence: "all",
        selectedSTLikelihood: "all",
        selectedSTRating: "all",
        selectedLTConsequence: "all",
        selectedLTLikelihood: "all",
        selectedLTRating: "all",
        selectedCriticalCampus: "all",
      },
      initialLoadstate: false,
      initialRegionLabel: null,
      rawCRAList: [],
      selectedRegion: null,
    }
  },
  computed: {
    currentRegionCode: {
      get() {
        return this.$store.state.mainModule.currentRegion || "north"
      }
    },
    currentAssessment: {
      get() {
        return this.$store.state.mainModule.currentAssessment
      }
    },
    dashboardURL: {
      get() {
        return this.$store.state.mainModule.assessmentMapScenes
          .filter(obj=>obj.code == this.$store.state.mainModule.currentAssessment)
          [0].climateDashboardURL
      }
    },
    regionList: {
      get() {
        return this.$store.state.mainModule.regionsList
      }
    },
    initialCRAList: {
      get() {
        let filteredList = this.rawCRAList;

        if (this.advanceFilters.selectedClimateHazard !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedClimateHazard === cra.climateHazardCode
          )
        }
        if (this.advanceFilters.selectedAssetLevel2 !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedAssetLevel2 === cra.subAssetTypeCode
          )
        }
        if (this.advanceFilters.selectedConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedConsequence === cra.consequenceCategoryCode
          )
        }
        if (this.advanceFilters.selectedCriticalCampus !== "all") {
          filteredList = filteredList.filter((cra) =>
            cra.criticalCampusCodes.some(cc => cc === this.advanceFilters.selectedCriticalCampus)
          )
        }
        // Short term
        if (this.advanceFilters.selectedSTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTConsequence === cra.riskAssessmentConsequenceSTCode
          )
        }
        if (this.advanceFilters.selectedSTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTLikelihood === cra.riskAssessmentLikelihoodSTCode
          )
        }
        if (this.advanceFilters.selectedSTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedSTRating === cra.riskAssessmentRatingSTCode
          )
        }
        // Long term
        if (this.advanceFilters.selectedLTConsequence !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTConsequence === cra.riskAssessmentConsequenceLTCode
          )
        }
        if (this.advanceFilters.selectedLTLikelihood !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTLikelihood === cra.riskAssessmentLikelihoodLTCode
          )
        }
        if (this.advanceFilters.selectedLTRating !== "all") {
          filteredList = filteredList.filter((cra) =>
            this.advanceFilters.selectedLTRating === cra.riskAssessmentRatingLTCode
          )
        }
        
        return filteredList
      }
    },
  },
  watch: {
    selectedRegion(val) {
      this.$store.commit('mainModule/setCurrentRegion', val)
      this.handleOnFetchCRA(val)
    },
  },
  methods: {
    handleOnFetchCRA: function(regionCode) {
      const current = this
      current.initialLoadstate = true

      const url = baseApiUrl + 'regionClimateRiskEntries'
      const data = {
        params: { regionCode },
      }

      if (regionCode) {
        axios.get(url, data).then(response=>{
          if (response.data) {
            const entries = response.data.entries
            entries.forEach(entry => {
              entry.criticalCampusCodes = entry.criticalCampuses.map(
                criticalCampus => criticalCampus.criticalCampusCode
              )
              entry.criticalCampusLabels = entry.criticalCampuses.map(
                criticalCampus => criticalCampus.criticalCampusLabel
              )
            })

            current.rawCRAList = entries
            current.initialRegionLabel = response.data.region.label
          }
        }).catch(() => {
          ElNotification({
            type: 'error',
            message: 'Failed to get data' + regionCode + "...",
            title: 'ERROR'
          })
        }).finally(() => {
          current.initialLoadstate = false
        })
      } else {
        current.initialLoadstate = false
      }
    },
    handleOnChangeFilter: function({ field, value }) {
      if (field === "selectedCampus") {
        this.selectedRegion = value
      } else {
        this.advanceFilters[field] = value
      }
    }
  },
  beforeMount(){
    const current = this
    const currentAssessmentItem = current.$route.path.replace("/", "")
    this.$store.commit('mainModule/setCurrentAssessment', currentAssessmentItem)
    current.selectedRegion = current.currentRegionCode
  }
}
</script>

<style scoped>
.arc-light-orange-bg {
  --el-avatar-bg-color: #EB894B
}
.arc-orange-font{
  color: #E4610F
}
.arc-light-orange-font{
  color: #EB894B
}
.arc-primary-font{
  color: #0C0808
}
.custom-icon-space{
  flex: none;
  margin-right: 10px;
}
.custom-iframe-powerbi {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
.custom-iframe-gis {
  min-height: 70vh;
  padding: 0;
  margin: 0;
  width: 100%;
  /* height: 100%; */
  border: none; overflow: hidden;
}
</style>
  