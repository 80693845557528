import { createRouter, createWebHistory } from "vue-router";

import AppHeader from "./layout/AppHeader.vue"
import Home from "./components/Home.vue";
import Portfolio from "./components/Portfolio.vue";
import Region from "./components/Region.vue";
import Enterprise from "./components/Enterprise.vue";
import Campus from "./components/Campus.vue";
import Methodology from "./components/Methodology.vue";
import About from "./components/About.vue";
import Support from "./components/Support.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        components:
        {
            header: AppHeader,
            default: Home
        }
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        components:
        {
            header: AppHeader,
            default: Portfolio
        }
    },
    {
        path: "/region",
        name: "Region",
        components:
        {
            header: AppHeader,
            default: Region
        }
    },
    {
        path: "/campus",
        name: "Campus",
        components:
        {
            header: AppHeader,
            default: Campus
        }
    },
    {
        path: "/enterprise",
        name: "Enterprise",
        components:
        {
            header: AppHeader,
            default: Enterprise
        }
    },
    {
        path: "/methodology",
        name: "Methodology",
        components:
        {
            header: AppHeader,
            default: Methodology
        }
    },
    {
        path: "/about",
        name: "About",
        components:
        {
            header: AppHeader,
            default: About
        }
    },
    {
        path: "/support",
        name: "Support",
        components:
        {
            header: AppHeader,
            default: Support
        }
    },
    // {
    //     path: '/',
    //     redirect: '/home'
    // }
]


let router = new createRouter({
    // eslint-disable-next-line no-undef
    history: createWebHistory(process.env.BASE_URL),
    routes
});


export default router;