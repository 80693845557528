import { MutationTypes } from "./types";

export default {
  [MutationTypes.CLEAR_ADAPTATION_TIMINGS](store) {
    store.adaptationTimingsList = [];
  },
  [MutationTypes.CLEAR_ADAPTATION_TYPES](store) {
    store.adaptationTypesList = [];
  },
  [MutationTypes.CLEAR_ADAPTATION_CATEGORIES](store) {
    store.adaptationCategoriesList = [];
  },
  [MutationTypes.CLEAR_ADAPTATION_COBENEFIT](store) {
    store.adaptationCobenefitList = [];
  },
  [MutationTypes.CLEAR_ADAPTATION_MEASURES](store) {
    store.campusAdaptationMeasuresList = [];
  },
  [MutationTypes.CLEAR_ASSESSMENT_CONSEQUENCES](store) {
    store.assessmentConsequencesList = [];
  },
  [MutationTypes.CLEAR_ASSESSMENT_LIKELIHOOD](store) {
    store.assessmentLikelihoodList = [];
  },
  [MutationTypes.CLEAR_ASSESSMENT_RATING](store) {
    store.assessmentRatingsList = [];
  },
  // [MutationTypes.CLEAR_CAMPUS_CORE_ADAPTATION_ACTIONS](store) {
  //   store.campusCoreAdaptationActionsList = [];
  // },
  [MutationTypes.CLEAR_CAMPUSES](store) {
    store.campusList = [];
  },
  [MutationTypes.CLEAR_CLIMATE_HAZARDS](store) {
    store.climateHazardsList = [];
  },
  [MutationTypes.CLEAR_CONSEQUENCE_CATEGORIES](store) {
    store.consequenceCategoriesList = [];
  },
  [MutationTypes.CLEAR_CURRENT_CAMPUS](store) {
    store.currentCampus = null;
  },
  [MutationTypes.CLEAR_PORTFOLIO_LIST](store) {
    store.portfolioList = [];
  },
  // [MutationTypes.CLEAR_REGION_ADAPTATION_MEASURES](store) {
  //   store.regionAdaptationMeasuresList = [];
  // },
  // [MutationTypes.CLEAR_REGION_CORE_ADAPTATION_ACTIONS](store) {
  //   store.regionCoreAdaptationActionsList = [];
  // },
  [MutationTypes.CLEAR_REGIONS](store) {
    store.regionsList = [];
  },
  [MutationTypes.CLEAR_RISK_TYPES](store) {
    store.riskTypesList = [];
  },
  [MutationTypes.CLEAR_SUB_ASSET_TYPES](store) {
    store.subAssetTypesList = [];
  },

  [MutationTypes.SET_ADAPTATION_TIMINGS](store, payload) {
    store.adaptationTimingsList.push(payload);
  },
  [MutationTypes.SET_ADAPTATION_TYPES](store, payload) {
    store.adaptationTypesList.push(payload);
  },
  [MutationTypes.SET_ADAPTATION_CATEGORIES](store, payload) {
    store.adaptationCategoriesList.push(payload);
  },
  [MutationTypes.SET_ADAPTATION_COBENEFIT](store, payload) {
    store.adaptationCobenefitList.push(payload);
  },
  [MutationTypes.SET_ASSESSMENT_CONSEQUENCES](store, payload) {
    store.assessmentConsequencesList.push(payload);
  },
  [MutationTypes.SET_ASSESSMENT_LIKELIHOOD](store, payload) {
    store.assessmentLikelihoodList.push(payload);
  },
  [MutationTypes.SET_ASSESSMENT_RATING](store, payload) {
    store.assessmentRatingsList.push(payload);
  },
  [MutationTypes.SET_ADAPTATION_MEASURES](store, payload) {
    store.campusAdaptationMeasuresList.push(payload);
  },
  [MutationTypes.SET_CAMPUS_CORE_ADAPTATION_ACTIONS](store, payload) {
    store.campusCoreAdaptationActionsList.push(payload);
  },
  [MutationTypes.SET_CAMPUSES](store, payload) {
    store.campusList.push(payload);
  },
  [MutationTypes.SET_CLIMATE_HAZARDS](store, payload) {
    store.climateHazardsList.push(payload);
  },
  [MutationTypes.SET_CONSEQUENCE_CATEGORIES](store, payload) {
    store.consequenceCategoriesList.push(payload);
  },
  [MutationTypes.SET_CURRENT_CAMPUS](store, payload) {
    store.currentCampus = payload;
  },
  [MutationTypes.SET_ENTERPRISE_LIST](store, payload) {
    store.enterpriseList = payload;
  },
  [MutationTypes.SET_PORTFOLIO_LIST](store, payload) {
    store.portfolioList = payload;
  },
  [MutationTypes.SET_REGION_ADAPTATION_MEASURES](store, payload) {
    store.regionAdaptationMeasuresList.push(payload);
  },
  [MutationTypes.SET_REGION_CORE_ADAPTATION_ACTIONS](store, payload) {
    store.regionCoreAdaptationActionsList.push(payload);
  },
  [MutationTypes.SET_REGIONS](store, payload) {
    store.regionsList.push(payload);
  },
  [MutationTypes.SET_SUB_ASSET_TYPES](store, payload) {
    store.subAssetTypesList.push(payload);
  },
  [MutationTypes.SET_RISK_TYPES](store, payload) {
    store.riskTypesList.push(payload);
  },
};
